.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 2s ease-in-out;
  background-color: var(--darkPrimaryColor);
  overflow: hidden;
  z-index: 9999;
}

.hiddenContainer {
  opacity: 0;
}

.block {
  position: fixed;
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 200px;

  @media (max-width: 767px) {
    width: 150px;
    height: 150px;
    border-radius: 75px;
  }
}

.water {
  animation: water ease-in-out 10s;
  transform: translateY(-20%);
}

.wave,
.deepWater {
  display: block;
  width: 100%;
}

.wave {
  background: url("../../../public/content/images//preloader.png");
  background-size: contain;
  height: 20px;
  animation: wave 10s ease-in-out infinite;
}

.deepWater {
  background-color: rgb(4, 103, 246);
  animation: complete 1s ease-in-out;
  animation-delay: 10s;
  animation-fill-mode: forwards;
  height: 600px;

  @media (max-width: 767px) {
    height: 200px;
  }
}

@keyframes complete {
  from {
    background-color: rgb(4, 103, 246);
  }

  to {
    background-color: rgb(8, 201, 143);
  }
}

@keyframes wave {
  from {
    background-position: 0px 0px;
  }

  to {
    background-position: 1000px 0px;
  }
}

@keyframes water {
  from {
    transform: translateY(50%);
  }

  to {
    transform: translateY(-20%);
  }
}
