/* k2d-200 - latin */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url('../public/fonts/k2d-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/k2d-200.woff') format('woff'); /* Modern Browsers */
}
/* k2d-regular - latin */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../public/fonts/k2d-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/k2d-regular.woff') format('woff'); /* Modern Browsers */
}
/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../public/fonts/inter-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/inter-regular.woff') format('woff'); /* Modern Browsers */
}
/* inter-500 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../public/fonts/inter-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/inter-500.woff') format('woff'); /* Modern Browsers */
}
/* brawler-regular - latin */
@font-face {
  font-family: 'Brawler';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../public/fonts/Brawler-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/Brawler-Regular.woff') format('woff'); /* Modern Browsers */
}
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;

}

::-webkit-scrollbar {
  width: 0;
}

body {
  /* display: block;
  height: calc(var(--vh, 1vh) * 100); */
  /* fix bug with render timeout */
  max-height: 100vh;
  position: relative;
  margin: 0;
  background: #001834;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
main {
  /* position: relative; */
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}
button,
input { /* 1 */
  overflow: visible;
}
button,
select { /* 1 */
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
button {
  padding: 0;
  border: none;
  background-color: transparent;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}
html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

:root {
  --accentColor: #0070EB;
  --lightColor: #FAFAFA;
  --darkPrimaryColor: #001834;
  --darkSecondaryColor: #001124;
}

:root {
  --titleFirst: 500 220px/240px "Inter";
  --titleSecond: 500 150px/180px "Inter";
  --titleThird: 500 100px/120px "Inter";
  --titleFourth: 500 80px/100px "Inter";
  --titleFifth: 500 44px/60px "Inter";
  --titleSixth: 500 40px/56px "Inter";
  --textFirst: 300 100px/130px "K2D";
  --textSecond: 300 50px/74px "K2D";
  --captionFirst: 500 60px/80px "K2D";
  --captionSecond: 500 50px/74px "K2D";
  --captionThird: 500 40px/60px "K2D";

}
@media (max-width: 3839px) {
  :root {
    --titleFirst: 500 140px/160px "Inter";
    --titleSecond: 500 100px/120px "Inter";
    --titleThird: 500 75px/96px "Inter";
    --titleFourth: 500 50px/68px "Inter";
    --titleFifth: 500 32px/44px "Inter";
    --titleSixth: 500 28px/40px "Inter";
    --textFirst: 300 70px/98px "K2D";
    --textSecond: 300 40px/60px "K2D";
    --captionFirst: 500 40px/60px "K2D";
    --captionSecond: 500 34px/50px "K2D";
    --captionThird: 500 28px/36px "K2D";
  }
}
@media (max-width: 2559px) {
  :root {
    --titleFirst: 500 110px/120px "Inter";
    --titleSecond: 500 80px/100px "Inter";
    --titleThird: 500 54px/70px "Inter";
    --titleFourth: 500 36px/50px "Inter";
    --titleFifth: 500 22px/34px "Inter";
    --titleSixth: 500 20px/30px "Inter";
    --textFirst: 300 48px/64px "K2D";
    --textSecond: 300 24px/40px "K2D";
    --captionFirst: 500 30px/46px "K2D";
    --captionSecond: 500 26px/40px "K2D";
    --captionThird: 500 20px/30px "K2D";
  }
}
@media (max-width: 1919px) {
  :root {
    --titleFirst: 500 84px/94px 'Inter';
    --titleSecond: 500 64px/74px 'Inter';
    --titleThird: 500 44px/54px 'Inter';
    --titleFourth: 500 24px/34px 'Inter';
    --titleFifth: 500 16px/26px 'Inter';
    --titleSixth: 500 14px/22px 'Inter';
    --textFirst: 200 33px/48px 'K2D';
    --textSecond: 200 18px/26px 'K2D';
    --captionFirst: 500 22px/32px 'Inter';
    --captionSecond: 500 18px/28px 'Inter';
    --captionThird: 500 14px/24px 'Inter';
  }
}
@media (max-width: 1365px) {
  :root {
    --titleFirst: 500 64px/74px "Inter";
    --titleSecond: 500 50px/62px "Inter";
    --titleThird: 500 34px/44px "Inter";
    --titleFourth: 500 24px/34px "Inter";
    --titleFifth: 500 16px/26px "Inter";
    --titleSixth: 500 14px/22px "Inter";
    --textFirst: 300 28px/38px "K2D";
    --textSecond: 300 14px/16px "K2D";
    --captionFirst: 500 18px/26px "K2D";
    --captionSecond: 500 16px/26px "K2D";
    --captionThird: 500 14px/18px "K2D";
  }
}
@media (max-width: 767px) {
  :root {
    --titleFirst: 500 34px/44px "Inter";
    --titleSecond: 500 28px/38px "Inter";
    --titleThird: 500 22px/32px "Inter";
    --titleFourth: 500 18px/28px "Inter";
    --titleFifth: 500 14px/20px "Inter";
    --titleSixth: 500 12px/18px "Inter";
    --textFirst: 300 22px/32px "K2D";
    --textSecond: 300 16px/24px "K2D";
    --captionFirst: 500 16px/24px "K2D";
    --captionThird: 500 12px/18px "K2D";
  }
}

.container {
  max-width: 3840px;
  padding-left: 74px;
  padding-right: 74px;
  margin: 0 auto;
}

@media (max-width: 3839px) {
  .container {
    max-width: 2560px;
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media (max-width: 2559px){
  .container {
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 1919px){
  .container {
    max-width: 1366px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 1365px){
  .container {
    max-width: 768px;
    padding-left: 36px;
    padding-right: 36px;
  }
}
@media (max-width: 767px){
  .container {
    max-width: 375px;
    padding-left: 13px;
    padding-right: 13px;
  }
}

