.container {
  position: absolute;
  pointer-events: none;
  backface-visibility: hidden;
  transform: scale(0);
  transition: transform 0.5s ease-in-out;
  z-index: 150;

  @media (max-width: 1365px) {
    display: none;
  }
}

.content {
  position: relative;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--accentColor);

  @media (max-width: 3839px) {
    width: 180px;
    height: 180px;
  }

  @media (max-width: 2559px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 1919px) {
    width: 90px;
    height: 90px;
  }
}

.text {
  width: 85%;
  height: 85%;
  position: absolute;
  animation: textRotate 10s normal linear infinite;
}

.arrow {
  width: 88px;
  height: 56px;

  @media (max-width: 3839px) {
    width: 64px;
    height: 41px;
  }

  @media (max-width: 2559px) {
    width: 42px;
    height: 27px;
  }

  @media (max-width: 1919px) {
    width: 32px;
    height: 20px;
  }
}

@keyframes textRotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg);
  }
}
