.transitionWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--darkPrimaryColor);
  z-index: 3000;
  opacity: 0;
  transition: transform 0.5s ease-in-out;
}

.background {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: var(--darkSecondaryColor);
  opacity: 0.9;
  z-index: 3200;
}

.transitionContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--darkPrimaryColor);
  overflow: hidden;
  z-index: 3300;
}

.letsGo {
  transform: translateY(200%);
}
