.back {
  top: 80px;
  left: 73px;
  position: absolute;
  width: 108px;
  height: 108px;
  border-radius: 108px;
  background-color: rgba(250, 250, 250, 0.1);
  backdrop-filter: blur(10px);
  background-image: url("../../../public/content/icons/arrow-back-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 66px 66px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 3839px) {
    top: 64px;
    left: 71px;
    width: 72px;
    height: 72px;
    border-radius: 80px;
    background-size: 45px 45px;
  }
  @media (max-width: 2559px) {
    top: 39px;
    left: 33px;
    width: 48px;
    height: 48px;
    background-size: 30px 30px;
  }
  @media (max-width: 1919px) {
    top: 28px;
    left: 28px;
    width: 32px;
    height: 32px;
    background-size: 20px 20px;
  }
  @media (max-width: 1365px) {
    display: none;
    background-color: rgba(0, 17, 36, 0.1);
  }
  @media (max-width: 767px) {
    z-index: 500;
  }
}

.hero {
  max-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  overflow: hidden;

  @media (max-width: 1365px) {
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-bottom: calc(var(--vh, 1vh) * 2);
  }
  @media (max-width: 767px) {
  }
}

.left {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 70px;
  padding-bottom: 70px;

  @media (max-width: 3839px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media (max-width: 2559px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: 1919px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: 1365px) {
    position: static;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    padding: 0;
    padding-left: 36px;
    padding-right: 36px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 24px;
    padding-bottom: calc(var(--vh, 1vh) * 3);
  }

  &_title {
    overflow: hidden;
    height: 480px;

    @media (max-width: 3839px) {
      height: 320px;
    }
    @media (max-width: 2559px) {
      height: 240px;
    }
    @media (max-width: 1919px) {
      height: 188px;
    }
    @media (max-width: 1365px) {
      height: 74px;
    }
    @media (max-width: 767px) {
      height: 44px;
    }

    h2 {
      text-align: center;
      font: var(--titleFirst);
      color: var(--lightColor);
      width: 1256px;
      height: 100%;
      transform: translateY(0%);
      transition: transform 1s ease-in-out;
      animation: showTitle 3s 1;
      animation-fill-mode: forwards;
      animation-delay: 0.2s;

      @media (max-width: 3839px) {
        width: 1172px;
      }
      @media (max-width: 2559px) {
        width: 627px;
      }
      @media (max-width: 1919px) {
        width: 642px;
      }
      @media (max-width: 1365px) {
        width: auto;
        text-align: left;
      }
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
}

.logotype {
  width: 525px;
  height: 130px;
  visibility: hidden;

  @media (max-width: 3839px) {
    width: 403px;
    height: 100px;
  }
  @media (max-width: 2559px) {
    width: 242px;
    height: 60px;
  }
  @media (max-width: 1919px) {
    width: 178px;
    height: 44px;
  }
  @media (max-width: 1365px) {
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
  }
  @media (max-width: 767px) {
    width: 121px;
    height: 30px;
    display: none;
  }
}

.right {
  width: 50%;
  transform: translateY(0%);
  transition: transform 1s ease-in-out;
  animation: show 3s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  @media (max-width: 1365px) {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.subtitle {
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 580px 389px;

  @media (max-width: 3839px) {
    padding: 279px 277px;
  }
  @media (max-width: 2559px) {
    padding: 192px 199px;
  }
  @media (max-width: 1919px) {
    padding: 130px 138px;
  }
  @media (max-width: 1365px) {
    padding: 100px 37px;
  }
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding: 100px 13px;
    gap: 32px;
  }

  p {
    font: var(--textFirst);
    color: var(--lightColor);
    width: 55%;

    @media (max-width: 1919px) {
      width: 55%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }

    span {
      color: var(--accentColor);
    }
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // gap: 76px;
  // padding-bottom: 400px;

  // @media (max-width: 3839px) {
  //   gap: 54px;
  //   padding-bottom: 250px;
  // }
  // @media (max-width: 2559px) {
  //   gap: 30px;
  //   padding-bottom: 170px;
  // }
  // @media (max-width: 1919px) {
  //   gap: 30px;
  //   padding-bottom: 0px;
  // }
  // @media (max-width: 1365px) {
  //   gap: 38px;
  //   margin-bottom: 100px;
  // }
  // @media (max-width: 767px) {
  //   gap: 16px;
  //   margin-bottom: 100px;
  // }

  &_container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 324px;

    @media (max-width: 3839px) {
      padding-bottom: 220px;
    }
    @media (max-width: 2559px) {
      padding-bottom: 150px;
    }
    @media (max-width: 1919px) {
      padding-bottom: 100px;
    }

    @media (max-width: 1365px) {
      padding-bottom: 60px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 82px;
    }

    article {
      position: relative;
      width: calc(50% - 38px);
      min-height: 972px;
      border-radius: 60px;
      padding: 100px;
      background-color: var(--darkSecondaryColor);
      margin-bottom: 76px;
      transform: translate(100vh, 100vw) skewX(-30deg);

      @media (max-width: 3839px) {
        width: calc(50% - 27px);
        min-height: 728px;
        border-radius: 40px;
        padding: 60px;
        margin-bottom: 54px;
      }
      @media (max-width: 2559px) {
        width: calc(50% - 15px);
        min-height: 450px;
        border-radius: 30px;
        padding: 40px;
        margin-bottom: 30px;
      }
      @media (max-width: 1919px) {
        min-height: 290px;
        padding: 24px;
        border-radius: 20px;
      }
      @media (max-width: 1365px) {
        min-height: 370px;
        width: calc(50% - 19px);
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-bottom: 38px;
      }
      @media (max-width: 767px) {
        width: 100%;
        min-height: 328px;
        margin-bottom: 16px;
      }

      div {
        position: relative;
        width: 250px;
        height: 250px;
        margin-bottom: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
        z-index: 1;

        @media (max-width: 3839px) {
          width: 180px;
          height: 180px;
          margin-bottom: 60px;
        }
        @media (max-width: 2559px) {
          width: 120px;
          height: 120px;
          margin-bottom: 40px;
        }
        @media (max-width: 1919px) {
          width: 80px;
          height: 80px;
          margin-bottom: 24px;
        }
        @media (max-width: 1365px) {
          width: 60px;
          height: 60px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--accentColor);
          opacity: 0.1;
          border-radius: 50%;
          z-index: 1;
        }
      }

      h6 {
        font: var(--titleFourth);
        color: var(--accentColor);
        margin-bottom: 100px;

        @media (max-width: 3839px) {
          margin-bottom: 60px;
        }
        @media (max-width: 2559px) {
          margin-bottom: 40px;
        }
        @media (max-width: 1919px) {
          margin-bottom: 24px;
        }
      }

      p {
        font: var(--textSecond);
        color: rgba(250, 250, 250, 0.5);

        @media (max-width: 1365px) {
          font-size: 18px;
          line-height: 26px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  // ul {
  //   width: 100%;
  // }
  // ul > li {
  //   display: flex;
  //   align-items: center;
  //   font: var(--titleFourth);
  //   color: var(--lightColor);
  //   padding: 80px;
  //   border-radius: 60px;
  //   border: 1px solid #87db00;

  //   @media (max-width: 3839px) {
  //     padding: 60px;
  //     border-radius: 40px;
  //   }
  //   @media (max-width: 2559px) {
  //     padding: 40px;
  //     border-radius: 30px;
  //   }
  //   @media (max-width: 1919px) {
  //     padding: 24px;
  //     border-radius: 20px;
  //   }

  //   &:not(:last-child) {
  //     margin-bottom: 62px;

  //     @media (max-width: 2559px) {
  //       margin-bottom: 40px;
  //     }
  //     @media (max-width: 1919px) {
  //       margin-bottom: 24px;
  //     }
  //     @media (max-width: 767px) {
  //       margin-bottom: 16px;
  //     }
  //   }

  //   span {
  //     display: inline-block;
  //     width: 200px;
  //     height: 200px;
  //     margin-right: 80px;
  //     border-radius: 50%;
  //     background: rgba(135, 219, 0, 0.1);
  //     background-image: url("../../../public/content/icons/services/done-list.svg");
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     background-size: 56%;

  //     @media (max-width: 3839px) {
  //       width: 120px;
  //       height: 120px;
  //       margin-right: 60px;
  //     }
  //     @media (max-width: 2559px) {
  //       width: 90px;
  //       height: 90px;
  //       margin-right: 40px;
  //     }
  //     @media (max-width: 1919px) {
  //       width: 60px;
  //       height: 60px;
  //       min-width: 60px;
  //       margin-right: 24px;
  //     }
  //     @media (max-width: 767px) {
  //       width: 40px;
  //       height: 40px;
  //       min-width: 40px;
  //       margin-right: 16px;
  //     }
  //   }
  // }
}

.contentLogisticWrapper {
  padding-bottom: 20vh;
}

.contentLogistic {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: var(--darkSecondaryColor);
  // gap: 76px;
  padding-bottom: 100px;
  border-radius: 60px;
  overflow: hidden;

  @media (max-width: 3839px) {
    // gap: 54px;
    // padding-bottom: 250px;
    border-radius: 40px;
  }
  @media (max-width: 2559px) {
    // gap: 30px;
    padding-bottom: 60px;
    border-radius: 30px;
  }
  @media (max-width: 1919px) {
    // gap: 30px;
    // padding-bottom: 0px;

    padding-bottom: 60px;
    border-radius: 20px;
  }
  @media (max-width: 1365px) {
    margin: 0 36px;
    padding-right: 36px;
    padding-left: 36px;
    // gap: 38px;
    // margin-bottom: 100px;
  }
  @media (max-width: 767px) {
    // gap: 16px;
    padding-bottom: 16px;
  }

  &_container {
    position: relative;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
    }

    article {
      position: relative;
      width: calc(50% - 38px);
      min-height: 972px;
      border-radius: 60px;
      padding: 100px;
      background-color: var(--darkSecondaryColor);
      transform: translate(100vh, 100vw) skewX(-30deg);

      @media (max-width: 3839px) {
        width: calc(50% - 27px);
        min-height: 728px;
        border-radius: 40px;
        padding: 60px;
      }
      @media (max-width: 2559px) {
        width: calc(50% - 15px);
        min-height: 450px;
        border-radius: 30px;
        padding: 40px;
      }
      @media (max-width: 1919px) {
        min-height: 290px;
        padding: 24px;
        border-radius: 20px;
      }
      @media (max-width: 1365px) {
        min-height: 370px;
        width: calc(50% - 19px);
        display: flex;
        flex-direction: column;
        align-items: start;
      }
      @media (max-width: 767px) {
        width: 100%;
        min-height: 328px;
      }

      div {
        position: relative;
        width: 250px;
        height: 250px;
        margin-bottom: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
        z-index: 1;

        @media (max-width: 3839px) {
          width: 180px;
          height: 180px;
          margin-bottom: 60px;
        }
        @media (max-width: 2559px) {
          width: 120px;
          height: 120px;
          margin-bottom: 40px;
        }
        @media (max-width: 1919px) {
          width: 80px;
          height: 80px;
          margin-bottom: 24px;
        }
        @media (max-width: 1365px) {
          width: 60px;
          height: 60px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--accentColor);
          opacity: 0.1;
          border-radius: 50%;
          z-index: 1;
        }
      }

      h6 {
        font: var(--titleFourth);
        color: var(--accentColor);
        margin-bottom: 100px;

        @media (max-width: 3839px) {
          margin-bottom: 60px;
        }
        @media (max-width: 2559px) {
          margin-bottom: 40px;
        }
        @media (max-width: 1919px) {
          margin-bottom: 24px;
        }
      }

      p {
        font: var(--textSecond);
        color: rgba(250, 250, 250, 0.5);

        @media (max-width: 1365px) {
          font-size: 18px;
          line-height: 26px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    article:nth-child(2) {
      @media (max-width: 767px) {
        top: 0;
        left: 0;
        position: absolute;
      }
    }
    article:nth-child(3) {
      top: 0;
      left: 0;
      position: absolute;
    }
    article:nth-child(4) {
      top: 0;
      right: 0;
      position: absolute;
    }
  }

  .textContent {
    display: flex;
    padding: 200px 100px;
    padding-bottom: 100px;
    margin-bottom: 100px;
    gap: 180px;

    @media (max-width: 3839px) {
      padding: 120px 60px;
      padding-bottom: 60px;
    }
    @media (max-width: 2559px) {
      padding: 60px;
      margin-bottom: 60px;
    }
    @media (max-width: 1919px) {
      gap: 24px;
      margin-bottom: 24px;
      padding: 50px;
    }
    @media (max-width: 1365px) {
      flex-direction: column;
    }
    @media (max-width: 767px) {
      padding: 24px;
      gap: 16px;
      margin-bottom: 16px;
    }

    h5 {
      font: var(--titleThird);
      color: var(--accentColor);
      width: calc(40% - 90px);

      @media (max-width: 1919px) {
        width: calc(40% - 12px);
      }
      @media (max-width: 1365px) {
        width: 100%;
      }
      @media (max-width: 767px) {
      }
    }

    p {
      font: var(--textSecond);
      color: rgba(250, 250, 250, 0.5);
      width: calc(60% - 90px);

      @media (max-width: 1919px) {
        width: calc(60% - 12px);
      }
      @media (max-width: 1365px) {
        width: 100%;
        font-size: 18px;
        line-height: 26px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  ul {
    width: 100%;
  }
  li {
    display: flex;
    align-items: center;
    font: var(--titleFourth);
    color: var(--lightColor);
    padding: 80px;
    border-radius: 60px;
    border: 1px solid #87db00;
    transform: translate(100vh, 100vw) skewX(-30deg);
    opacity: 0;

    @media (max-width: 3839px) {
      padding: 60px;
      border-radius: 40px;
    }
    @media (max-width: 2559px) {
      padding: 40px;
      border-radius: 30px;
    }
    @media (max-width: 1919px) {
      padding: 24px;
      border-radius: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 62px;

      @media (max-width: 2559px) {
        margin-bottom: 40px;
      }
      @media (max-width: 1919px) {
        margin-bottom: 24px;
      }
      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    span {
      display: inline-block;
      width: 200px;
      height: 200px;
      margin-right: 80px;
      border-radius: 50%;
      background: rgba(135, 219, 0, 0.1);
      background-image: url("../../../public/content/icons/services/done-list.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 56%;

      @media (max-width: 3839px) {
        width: 120px;
        height: 120px;
        margin-right: 60px;
      }
      @media (max-width: 2559px) {
        width: 90px;
        height: 90px;
        margin-right: 40px;
      }
      @media (max-width: 1919px) {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin-right: 24px;
      }
      @media (max-width: 767px) {
        width: 40px;
        height: 40px;
        min-width: 40px;
        margin-right: 16px;
      }
    }
  }
}

@keyframes show {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes showTitle {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
