.back {
  top: 80px;
  left: 73px;
  position: absolute;
  width: 108px;
  height: 108px;
  border-radius: 108px;
  background-color: rgba(250, 250, 250, 0.1);
  backdrop-filter: blur(10px);
  background-image: url("../../../public/content/icons/arrow-back-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 66px 66px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 3839px) {
    top: 64px;
    left: 71px;
    width: 72px;
    height: 72px;
    border-radius: 80px;
    background-size: 45px 45px;
  }
  @media (max-width: 2559px) {
    top: 39px;
    left: 33px;
    width: 48px;
    height: 48px;
    background-size: 30px 30px;
  }
  @media (max-width: 1919px) {
    top: 28px;
    left: 28px;
    width: 32px;
    height: 32px;
    background-size: 20px 20px;
  }
  @media (max-width: 1365px) {
    display: none;
    background-color: rgba(0, 17, 36, 0.1);
  }
  @media (max-width: 767px) {
    z-index: 500;
  }
}

.hero {
  max-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  overflow: hidden;

  @media (max-width: 1365px) {
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-bottom: calc(var(--vh, 1vh) * 2);
  }
  @media (max-width: 767px) {
  }
}

.left {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 70px;
  padding-bottom: 70px;

  @media (max-width: 3839px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media (max-width: 2559px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: 1919px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: 1365px) {
    position: static;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    padding: 0;
    padding-left: 36px;
    padding-right: 36px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 24px;
    padding-bottom: calc(var(--vh, 1vh) * 3);
  }

  &_title {
    overflow: hidden;
    height: 480px;

    @media (max-width: 3839px) {
      height: 320px;
    }
    @media (max-width: 2559px) {
      height: 240px;
    }
    @media (max-width: 1919px) {
      height: 188px;
    }
    @media (max-width: 1365px) {
      height: 74px;
    }
    @media (max-width: 767px) {
      height: 44px;
    }

    h2 {
      text-align: center;
      font: var(--titleFirst);
      color: var(--lightColor);
      width: 1256px;
      height: 100%;
      transform: translateY(0%);
      transition: transform 1s ease-in-out;
      animation: showTitle 3s 1;
      animation-fill-mode: forwards;
      animation-delay: 0.2s;

      @media (max-width: 3839px) {
        width: 1172px;
      }
      @media (max-width: 2559px) {
        width: 627px;
      }
      @media (max-width: 1919px) {
        width: 642px;
      }
      @media (max-width: 1365px) {
        width: auto;
        text-align: left;
      }
    }
  }
}

.logotype {
  width: 525px;
  height: 130px;
  visibility: hidden;

  @media (max-width: 3839px) {
    width: 403px;
    height: 100px;
  }
  @media (max-width: 2559px) {
    width: 242px;
    height: 60px;
  }
  @media (max-width: 1919px) {
    width: 178px;
    height: 44px;
  }
  @media (max-width: 1365px) {
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
  }
  @media (max-width: 767px) {
    width: 121px;
    height: 30px;
    display: none;
  }
}

.right {
  width: 50%;
  transform: translateY(0%);
  transition: transform 1s ease-in-out;
  animation: show 3s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  @media (max-width: 1365px) {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 400px;
  padding-bottom: 186px;

  @media (max-width: 3839px) {
    padding-top: 250px;
    padding-bottom: 125px;
  }
  @media (max-width: 2559px) {
    padding-top: 170px;
    padding-bottom: 100px;
  }
  @media (max-width: 1919px) {
    padding-top: 130px;
    padding-bottom: 60px;
  }
  @media (max-width: 1365px) {
    padding-top: 100px;
    padding-bottom: 50px;
    flex-direction: column;
    align-items: start;
    gap: 30px;
  }

  h3 {
    font: var(--titleThird);
    color: var(--accentColor);
  }

  ul {
    display: flex;

    @media (max-width: 767px) {
      width: 100%;
      padding-top: 10px;
      height: 60px;
      overflow-x: scroll;
    }

    li {
      &:not(:last-child) {
        margin-right: 50px;

        @media (max-width: 3839px) {
          margin-right: 44px;
        }
        @media (max-width: 2559px) {
          margin-right: 24px;
        }
        @media (max-width: 767px) {
          margin-right: 14px;
        }
      }
    }
  }
}

.link {
  padding: 16px 38px;
  font: var(--captionSecond);
  color: var(--lightColor);
  background: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 1000px;
  text-transform: uppercase;

  @media (max-width: 3839px) {
    padding: 12px 30px;
  }
  @media (max-width: 2559px) {
    padding: 10px 20px;
  }
  @media (max-width: 1919px) {
    padding: 6px 12px;
  }
  @media (max-width: 1365px) {
    padding: 4px 10px;
  }
}

.activeLink {
  color: var(--accentColor);
}

.tableContainer {
  width: 100%;
  padding-bottom: 400px;

  @media (max-width: 3839px) {
    padding-bottom: 250px;
  }
  @media (max-width: 2559px) {
    padding-bottom: 170px;
  }
  @media (max-width: 1919px) {
    padding-bottom: 130px;
  }
  @media (max-width: 1365px) {
    padding-bottom: 100px;
  }
  @media (max-width: 767px) {
    width: max-content;
    overflow-x: scroll;
  }
}

.table {
  width: 100%;
  position: relative;
  display: table;
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
  @media (max-width: 767px) {
    width: 917px;
  }
}

.thead {
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: var(--darkSecondaryColor);
      border-radius: 60px;
      z-index: -1;

      @media (max-width: 3839px) {
        border-radius: 40px;
      }
      @media (max-width: 2559px) {
        border-radius: 30px;
      }
      @media (max-width: 1919px) {
        border-radius: 20px;
      }
      @media (max-width: 1365px) {
        border-radius: 10px;
      }
    }

    li {
      width: 15%;
      font: var(--captionSecond);
      color: rgba(250, 250, 250, 0.5);
      text-transform: uppercase;
      text-align: left;
      padding: 48px 64px;

      @media (max-width: 3839px) {
        padding: 30px;
      }
      @media (max-width: 2559px) {
        padding: 16px 20px;
      }
      @media (max-width: 1919px) {
        padding: 12px 16px;
      }
      @media (max-width: 1365px) {
        padding: 8px;
        font: var(--captionThird);
      }
      @media (max-width: 767px) {
        padding: 6px;
        padding-right: 10px;
      }

      &:not(:last-child) {
        @media (max-width: 767px) {
          padding-right: 50px;
        }
      }
    }
  }
}

.tbody {
  ul {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // clip-path: inset(0);

    &:nth-child(even) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(250, 250, 250, 0.03);
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: 60px;

        @media (max-width: 3839px) {
          border-radius: 40px;
        }
        @media (max-width: 2559px) {
          border-radius: 30px;
        }
        @media (max-width: 1919px) {
          border-radius: 20px;
        }
        @media (max-width: 1365px) {
          border-radius: 10px;
        }
      }
    }

    li {
      width: 15%;
      display: table-cell;
      font: var(--textSecond);
      color: var(--lightColor);
      padding: 48px 64px;

      @media (max-width: 3839px) {
        padding: 30px;
      }
      @media (max-width: 2559px) {
        padding: 16px 20px;
      }
      @media (max-width: 1919px) {
        padding: 12px 16px;
      }
      @media (max-width: 767px) {
        padding: 6px;
        padding-right: 10px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

@keyframes show {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes showTitle {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
