.container {
  width: 250px;
  height: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--accentColor);
  cursor: pointer;

  @media (max-width: 3839px) {
    width: 180px;
    height: 180px;
  }

  @media (max-width: 2559px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 1919px) {
    width: 90px;
    height: 90px;
  }

  @media (max-width: 1365px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 767px) {
    width: 70px;
    height: 70px;
  }
}

.arrow {
  width: 62px;
  height: 62px;

  @media (max-width: 3839px) {
    width: 45px;
    height: 45px;
  }

  @media (max-width: 2559px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 1919px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1365px) {
    width: 25px;
    height: 25px;
  }

  @media (max-width: 767px) {
    width: 17px;
    height: 17px;
  }
}

.text {
  width: 215px;
  height: 215px;
  position: absolute;
  animation: textRotate 15s normal linear infinite;

  @media (max-width: 3839px) {
    width: 155px;
    height: 155px;
  }

  @media (max-width: 2559px) {
    width: 103px;
    height: 103px;
  }

  @media (max-width: 1919px) {
    width: 78px;
    height: 78px;
  }

  @media (max-width: 1365px) {
    width: 86px;
    height: 86px;
  }

  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
  }
}

@keyframes textRotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.aboutHero {
  position: absolute;
  background-color: transparent;
  z-index: 10;
  bottom: 32px;
}
.about {
  position: static;
  background-color: var(--accentColor);
  z-index: 10;
  bottom: 0;
}
