.back {
  top: 80px;
  left: 73px;
  position: absolute;
  width: 108px;
  height: 108px;
  border-radius: 108px;
  background-color: rgba(250, 250, 250, 0.1);
  backdrop-filter: blur(10px);
  background-image: url("../../../public/content/icons/arrow-back-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 66px 66px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 3839px) {
    top: 64px;
    left: 71px;
    width: 72px;
    height: 72px;
    border-radius: 80px;
    background-size: 45px 45px;
  }
  @media (max-width: 2559px) {
    top: 39px;
    left: 33px;
    width: 48px;
    height: 48px;
    background-size: 30px 30px;
  }
  @media (max-width: 1919px) {
    top: 28px;
    left: 28px;
    width: 32px;
    height: 32px;
    background-size: 20px 20px;
  }
  @media (max-width: 1365px) {
    display: none;
    background-color: rgba(0, 17, 36, 0.1);
  }

  @media (max-width: 767px) {
    z-index: 500;
  }
}

.hero {
  max-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  overflow: hidden;

  @media (max-width: 1365px) {
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-bottom: calc(var(--vh, 1vh) * 2);
  }
  @media (max-width: 767px) {
  }
}

.left {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 70px;
  padding-bottom: 70px;

  @media (max-width: 3839px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media (max-width: 2559px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: 1919px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: 1365px) {
    position: static;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    padding: 0;
    padding-left: 36px;
    padding-right: 36px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 24px;
    padding-bottom: calc(var(--vh, 1vh) * 3);
  }

  &_title {
    overflow: hidden;
    height: 480px;

    @media (max-width: 3839px) {
      height: 320px;
    }
    @media (max-width: 2559px) {
      height: 240px;
    }
    @media (max-width: 1919px) {
      height: 188px;
    }
    @media (max-width: 1365px) {
      height: 74px;
    }
    @media (max-width: 767px) {
      height: 44px;
    }

    h2 {
      text-align: center;
      font: var(--titleFirst);
      color: var(--lightColor);
      width: 1256px;
      height: 100%;
      transform: translateY(0%);
      transition: transform 1s ease-in-out;
      animation: showTitle 3s 1;
      animation-fill-mode: forwards;
      animation-delay: 0.2s;

      @media (max-width: 3839px) {
        width: 1172px;
      }
      @media (max-width: 2559px) {
        width: 627px;
      }
      @media (max-width: 1919px) {
        width: 642px;
      }
      @media (max-width: 1365px) {
        width: auto;
        text-align: left;
      }
    }
  }
}

.logotype {
  width: 525px;
  height: 130px;
  visibility: hidden;

  @media (max-width: 3839px) {
    width: 403px;
    height: 100px;
  }
  @media (max-width: 2559px) {
    width: 242px;
    height: 60px;
  }
  @media (max-width: 1919px) {
    width: 178px;
    height: 44px;
  }
  @media (max-width: 1365px) {
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
  }
  @media (max-width: 767px) {
    width: 121px;
    height: 30px;
    display: none;
  }
}

.right {
  width: 50%;
  transform: translateY(0%);
  transition: transform 1s ease-in-out;
  animation: show 3s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  @media (max-width: 1365px) {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.facts {
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  border-bottom: 1px solid rgba(250, 250, 250, 0.1);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../public/content/images/secondary_pages/pools/4k/background.png");
  overflow: hidden;

  @media (max-width: 3839px) {
    background-image: url("../../../public/content/images/secondary_pages/pools/2k/background.png");
  }

  @media (max-width: 1919px) {
    background-image: url("../../../public/content/images/secondary_pages/pools/desctop/background.png");
  }

  @media (max-width: 1365px) {
    background-image: url("../../../public/content/images/secondary_pages/pools/laptop/background.png");
  }

  @media (max-width: 767px) {
    background-image: url("../../../public/content/images/secondary_pages/pools/mobile/background.png");
  }

  &_container {
    display: flex;
    align-items: center;
    gap: 494px;
    padding: 0 74px;

    @media (max-width: 3839px) {
      gap: 225px;
      padding: 0 70px;
    }
    @media (max-width: 2559px) {
      gap: 150px;
      padding: 0 32px;
    }
    @media (max-width: 1919px) {
      gap: 125px;
      padding: 0 25px;
    }
    @media (max-width: 1365px) {
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 20px;
      padding: 0 36px;
    }
    @media (max-width: 767px) {
      gap: 24px;
      padding: 0 13px;
    }

    h3 {
      font: var(--titleThird);
      color: var(--accentColor);
      min-width: 734px;

      @media (max-width: 3839px) {
        min-width: 599px;
      }
      @media (max-width: 2559px) {
        min-width: 397px;
      }
      @media (max-width: 1919px) {
        min-width: 323px;
      }
      @media (max-width: 1365px) {
        width: auto;
      }
    }

    ul {
      display: flex;
      gap: 78px;

      @media (max-width: 3839px) {
        gap: 42px;
      }
      @media (max-width: 2559px) {
        gap: 40px;
      }
      @media (max-width: 1919px) {
        gap: 30px;
      }
      @media (max-width: 1365px) {
        gap: 24px;
      }
      @media (max-width: 767px) {
        gap: 20px;
      }

      li {
        width: 1000px;
        height: 970px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 60px;
        padding: 200px 0;
        background-color: var(--darkSecondaryColor);

        @media (max-width: 3839px) {
          width: 612px;
          height: 610px;
          padding: 120px 0;
          border-radius: 40px;
        }
        @media (max-width: 2559px) {
          width: 500px;
          height: 534px;
          padding: 96px 0;
          border-radius: 30px;
        }
        @media (max-width: 1919px) {
          width: 376px;
          height: 392px;
          padding: 72px 0px;
          border-radius: 20px;
        }
        @media (max-width: 1365px) {
          width: 332px;
          height: 354px;
          padding: 56px 0px;
        }
        @media (max-width: 767px) {
          width: 230px;
          height: 272px;
          padding: 48px 0px;
        }

        div:nth-child(1) {
          position: relative;
          width: 300px;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 112, 235, 0.1);
          border-radius: 300px;

          @media (max-width: 3839px) {
            width: 180px;
            height: 180px;
          }
          @media (max-width: 1919px) {
            width: 140px;
            height: 140px;
          }
          @media (max-width: 767px) {
            width: 100px;
            height: 100px;
          }

          img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }

        div:nth-child(2) {
          text-align: center;
          h4 {
            color: var(--accentColor);
            font: var(--titleFourth);
            span {
              font: var(--titleFourth);
            }
          }

          & > span {
            font: var(--captionFirst);
            color: var(--lightColor);
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.processWrapper {
  width: 100%;
  // height: calc(var(--vh, 1vh) * 100);
  // max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.process {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding-left: 74px;
  padding-right: 74px;
  padding-top: 400px;
  padding-bottom: 400px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.1);

  @media (max-width: 3839px) {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 250px;
    padding-bottom: 250px;
  }

  @media (max-width: 2559px) {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 170px;
    padding-bottom: 170px;
  }

  @media (max-width: 1919px) {
    padding-left: 25px;
    padding-right: 25px;
    max-width: 1366px;
    padding-top: 130px;
    padding-bottom: 130px;
  }

  @media (max-width: 1365px) {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (max-width: 767px) {
    justify-content: center;
    padding-left: 13px;
    padding-right: 13px;
  }

  h3 {
    font: var(--titleThird);
    color: var(--accentColor);

    @media (max-width: 1365px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }

  &_container {
    width: 2434px;
    & > p {
      font: var(--textFirst);
      color: var(--lightColor);
      margin-bottom: calc(var(--vh, 1vh) * 6);

      @media (max-width: 1365px) {
        margin-bottom: 51px;
      }
    }

    @media (max-width: 3839px) {
      width: 1594px;
    }
    @media (max-width: 2559px) {
      width: 1226px;
    }
    @media (max-width: 1919px) {
      width: 866px;
    }
    @media (max-width: 1365px) {
      width: 100%;
    }

    ul {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: calc(var(--vh, 1vh) * 3);

      @media (max-width: 1365px) {
        gap: 30px;
      }

      li {
        position: static;
        width: 1178px;
        height: 994px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        border-radius: 60px;
        padding: 0 100px;
        padding-top: 100px;
        gap: 100px;
        background-color: var(--darkSecondaryColor);
        transform: translate(60vh, 30vw) skewX(-30deg);

        @media (max-width: 3839px) {
          width: 770px;
          height: 780px;
          border-radius: 40px;
          padding: 0 60px;
          padding-top: 60px;
          gap: 60px;
        }
        @media (max-width: 2559px) {
          width: 598px;
          height: 440px;
          border-radius: 30px;
          padding: 0 40px;
          padding-top: 40px;
          gap: 40px;
        }
        @media (max-width: 1919px) {
          width: 418px;
          height: 282px;
          border-radius: 20px;
          padding: 0 24px;
          padding-top: 24px;
          gap: 24px;
        }
        @media (max-width: 1365px) {
          width: 332.5px;
          height: 334px;
        }
        @media (max-width: 767px) {
          width: 100%;
          padding: 0 24px;
          padding-top: 24px;
        }

        div {
          width: 250px;
          height: 250px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 112, 235, 0.1);
          border-radius: 250px;

          @media (max-width: 3839px) {
            width: 180px;
            height: 180px;
          }
          @media (max-width: 2559px) {
            width: 120px;
            height: 120px;
          }
          @media (max-width: 1919px) {
            width: 80px;
            height: 80px;
          }
          @media (max-width: 767px) {
            width: 60px;
            height: 60px;
          }

          img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }

        p {
          font: var(--textSecond);
          color: var(--lightColor);
          opacity: 0.5;
          margin: 0;

          @media (max-width: 1365px) {
            font-size: 18px;
            line-height: 26px;
          }
        }
      }

      // li:nth-child(2) {
      //   @media (max-width: 767px) {
      //     top: 0;
      //     left: 0;
      //     position: absolute;
      //   }
      // }
      // li:nth-child(3) {
      //   top: 0;
      //   left: 0;
      //   position: absolute;
      // }
      // li:nth-child(4) {
      //   top: 0;
      //   right: 0;
      //   position: absolute;
      // }
    }
  }
}

.flexibility {
  // width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-bottom: 1px solid rgba(250, 250, 250, 0.1);

  &_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1365px) {
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }
  }

  h3 {
    width: 1085px;
    font: var(--titleThird);
    color: #87db00;
    // transform: translateX(40vw) rotate(90deg) scale(0.3) skew(0.312rad);
    // opacity: 0;

    @media (max-width: 3839px) {
      width: 758px;
    }
    @media (max-width: 2559px) {
      width: 497px;
    }
    @media (max-width: 1919px) {
      width: 398px;
    }
    @media (max-width: 1365px) {
      width: auto;
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      width: 297px;
      margin-bottom: 24px;
    }
  }

  ul {
    display: flex;
    gap: 78px;

    @media (max-width: 3839px) {
      gap: 54px;
    }
    @media (max-width: 2559px) {
      gap: 30px;
    }

    @media (max-width: 1365px) {
      width: 100%;
      flex-direction: column;
      gap: 16px;
    }

    li {
      width: 759.33px;
      height: 940px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding: 0 100px;
      padding-top: 100px;
      gap: 100px;
      border-radius: 60px;
      background-color: var(--darkSecondaryColor);
      transform: translateX(40vw) rotate(90deg) scale(0.3) skew(-0.06turn, 18deg);
      opacity: 0;

      @media (max-width: 3839px) {
        width: 495.33px;
        height: 720px;
        border-radius: 40px;
        padding: 0 60px;
        padding-top: 60px;
        gap: 60px;
      }
      @media (max-width: 2559px) {
        width: 388.67px;
        height: 432px;
        border-radius: 30px;
        padding: 0 40px;
        padding-top: 40px;
        gap: 40px;
      }
      @media (max-width: 1919px) {
        width: 268.67px;
        height: 296px;
        padding: 0 24px;
        padding-top: 24px;
        gap: 24px;
        border-radius: 20px;
      }
      @media (max-width: 1365px) {
        flex-direction: row;
        width: 100%;
        height: 108px;
        padding-top: 0;
        align-items: center;
      }
      @media (max-width: 767px) {
        height: auto;
        padding: 24px;
      }

      div {
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 250px;
        background-color: rgba(135, 219, 0, 0.1);

        @media (max-width: 3839px) {
          width: 180px;
          height: 180px;
        }
        @media (max-width: 2559px) {
          width: 120px;
          height: 120px;
        }
        @media (max-width: 1919px) {
          width: 80px;
          height: 80px;
        }
        @media (max-width: 1365px) {
          width: 60px;
          height: 60px;
          min-width: 60px;
          min-height: 60px;
        }

        img {
          width: 50%;
          height: 50%;
          object-fit: contain;
        }
      }

      p {
        font: var(--textSecond);
        color: var(--lightColor);
        opacity: 0.5;

        @media (max-width: 1365px) {
          font-size: 18px;
          line-height: 26px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      span {
        font: var(--textFirst);
        color: var(--lightColor);
        opacity: 0.5;
      }
    }
  }
}

.approach {
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-position-y: calc(var(--vh, 1vh) * 10);
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../../public/content/images/secondary_pages/pools/4k/background.png");
  overflow: hidden;

  @media (max-width: 3839px) {
    background-image: url("../../../public/content/images/secondary_pages/pools/2k/background.png");
  }

  @media (max-width: 2559px) {
    // height: calc(var(--vh, 1vh) * 400);
    background-image: url("../../../public/content/images/secondary_pages/pools/desctop/background.png");
  }

  @media (max-width: 1919px) {
    background-image: url("../../../public/content/images/secondary_pages/pools/laptop/background.png");
  }

  @media (max-width: 1365px) {
    // height: calc(var(--vh, 1vh) * 300);
  }

  @media (max-width: 767px) {
    // height: calc(var(--vh, 1vh) * 450);
    background-image: url("");
  }

  &_container {
    display: flex;
    align-items: start;
    justify-content: space-between;

    @media (max-width: 1365px) {
      flex-direction: column;
      align-items: start;
      justify-content: center;
    }

    h3 {
      font: var(--titleThird);
      color: var(--accentColor);
      margin-left: 74px;

      @media (max-width: 3839px) {
        margin-left: 70px;
      }
      @media (max-width: 2559px) {
        margin-left: 32px;
      }
      @media (max-width: 1919px) {
        margin-left: 25px;
      }
      @media (max-width: 1365px) {
        margin-bottom: 30px;
        margin-left: 36px;
      }
      @media (max-width: 767px) {
        margin-bottom: 16px;
        margin-left: 13px;
      }
    }

    ul {
      position: relative;
      width: 2434px;
      height: 1000px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-right: 74px;

      @media (max-width: 3839px) {
        width: 1594px;
        height: 830px;
        gap: 54px;
        margin-right: 70px;
      }
      @media (max-width: 2559px) {
        width: 1226px;
        height: 594px;
        gap: 30px;
        margin-right: 32px;
      }
      @media (max-width: 1919px) {
        width: 866px;
        height: 418px;
        margin-right: 25px;
      }
      @media (max-width: 1365px) {
        gap: 24px;
        width: 100%;
        margin: 0 36px;
      }
      @media (max-width: 767px) {
        margin: 0 13px;
      }

      li {
        top: 0;
        left: 0;
        position: absolute;
        width: 2434px;
        height: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 190px 0;
        border-radius: 60px;
        background-color: var(--darkSecondaryColor);

        &:nth-child(2) {
          top: calc(var(--vh, 1vh) * 2);
          transform: translateY(200%) scale(1.5);
        }
        &:nth-child(3) {
          top: calc(var(--vh, 1vh) * 4);
          transform: translateY(300%) scale(2);
        }
        &:nth-child(4) {
          top: calc(var(--vh, 1vh) * 6);
          transform: translateY(400%) scale(2);
        }
        &:nth-child(5) {
          top: calc(var(--vh, 1vh) * 8);
          transform: translateY(500%) scale(2);
        }
        &:nth-child(6) {
          top: calc(var(--vh, 1vh) * 4);
          transform: translateY(600%) scale(2);
        }

        @media (max-width: 3839px) {
          width: 1594px;
          height: 830px;
          padding: 150px 0;
          border-radius: 40px;
        }
        @media (max-width: 2559px) {
          width: 1226px;
          height: 594px;
          padding: 102px 0;
          border-radius: 30px;
        }
        @media (max-width: 1919px) {
          width: 866px;
          height: 418px;
          padding: 70px 0;
          border-radius: 20px;
        }
        @media (max-width: 1365px) {
          width: calc(100% - 72px);
        }
        @media (max-width: 767px) {
          padding: 54px 0;
          width: calc(100% - 26px);
        }

        div:nth-child(1) {
          width: 314px;
          height: 314px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 314px;
          background-color: rgba(0, 112, 235, 0.1);

          @media (max-width: 3839px) {
            width: 250px;
            height: 250px;
          }
          @media (max-width: 2559px) {
            width: 180px;
            height: 180px;
          }
          @media (max-width: 1919px) {
            width: 140px;
            height: 140px;
          }
          @media (max-width: 767px) {
            width: 100px;
            height: 100px;
          }

          img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }

        div:nth-child(2) {
          width: 1934px;
          display: flex;
          flex-direction: column;
          gap: 32px;
          text-align: center;

          @media (max-width: 3839px) {
            width: 1094px;
          }
          @media (max-width: 2559px) {
            width: 726px;
          }
          @media (max-width: 1919px) {
            width: 560px;
            gap: 16px;
          }
          @media (max-width: 1365px) {
            width: 419px;
            gap: 16px;
          }
          @media (max-width: 767px) {
            width: 300px;
          }

          h4 {
            font: var(--titleFourth);
            color: var(--accentColor);
          }

          p {
            font: var(--captionSecond);
            color: var(--lightColor);
            opacity: 0.5;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.charts {
  width: 100%;
  height: calc(var(--vh, 1vh) * 300);
  max-height: calc(var(--vh, 1vh) * 300);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.numRSA {
  display: flex;
  align-items: center;
  overflow: hidden;

  &_container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1365px) {
      flex-direction: column;
      justify-content: center;
    }

    h3 {
      width: 969px;
      font: var(--titleThird);
      color: var(--accentColor);

      @media (max-width: 3839px) {
        width: 610px;
      }
      @media (max-width: 2559px) {
        width: 395px;
      }
      @media (max-width: 1919px) {
        width: 340px;
      }
      @media (max-width: 1365px) {
        width: auto;
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
    &_block {
      display: flex;
      justify-content: center;
      width: 2637px;
      height: 1205px;

      @media (max-width: 3839px) {
        width: 1784px;
        height: 607px;
      }
      @media (max-width: 2559px) {
        width: 1342px;
        height: 597px;
      }
      @media (max-width: 1919px) {
        width: 939px;
        height: 535px;
      }
      @media (max-width: 1365px) {
        width: 707px;
        height: 532px;
      }
      @media (max-width: 767px) {
        width: 348px;
        height: 358px;
      }
    }
  }
}
.numVessels {
  display: flex;
  align-items: center;
  overflow: hidden;

  &_container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1365px) {
      flex-direction: column;
      justify-content: center;
    }

    h3 {
      width: 969px;
      font: var(--titleThird);
      color: var(--accentColor);

      @media (max-width: 3839px) {
        width: 610px;
      }
      @media (max-width: 2559px) {
        width: 395px;
      }
      @media (max-width: 1919px) {
        width: 340px;
      }
      @media (max-width: 1365px) {
        width: auto;
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
    &_block {
      display: flex;
      justify-content: center;
      width: 2637px;
      height: 1205px;

      @media (max-width: 3839px) {
        width: 1784px;
        height: 607px;
      }
      @media (max-width: 2559px) {
        width: 1342px;
        height: 597px;
      }
      @media (max-width: 1919px) {
        width: 939px;
        height: 535px;
      }
      @media (max-width: 1365px) {
        width: 707px;
        height: 532px;
      }
      @media (max-width: 767px) {
        width: 348px;
        height: 358px;
      }
    }
  }
}
.cargoes {
  display: flex;
  align-items: center;
  overflow: hidden;

  &_container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1365px) {
      flex-direction: column;
      justify-content: center;
    }

    h3 {
      width: 969px;
      font: var(--titleThird);
      color: var(--accentColor);

      @media (max-width: 3839px) {
        width: 610px;
      }
      @media (max-width: 2559px) {
        width: 395px;
      }
      @media (max-width: 1919px) {
        width: 340px;
      }
      @media (max-width: 1365px) {
        width: auto;
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
    &_block {
      display: flex;
      justify-content: center;
      width: 2637px;
      height: 1205px;

      @media (max-width: 3839px) {
        width: 1784px;
        height: 607px;
      }
      @media (max-width: 2559px) {
        width: 1342px;
        height: 597px;
      }
      @media (max-width: 1919px) {
        width: 939px;
        height: 535px;
      }
      @media (max-width: 1365px) {
        width: 707px;
        height: 532px;
      }
      @media (max-width: 767px) {
        width: 348px;
        height: 358px;
      }
    }
  }
}

@keyframes show {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes showTitle {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
